import React, { Fragment, useState } from 'react';
import { ExpandLess, ExpandMore } from '@material-ui/icons';
import { Button, Collapse, Grid, List, ListItem, ListItemText, MenuItem, Paper, TextField, Theme } from '@material-ui/core';
import Layout from '../../components/layout';
import SEO from '../../components/seo';
import Header from '../../components/header';
import { faqs } from '../../utils/tu-instituto/constants';
import { useStyles } from '../../utils/tu-instituto/styles';
import { useGlobalStyles } from '../../utils/styles';

const FAQ = () => {
  const classes = useStyles();
  const globalClasses = useGlobalStyles();
  const [category, setCategory] = useState('');
  const [data, setData] = useState<{ category: string, question: string }>({
    category: '',
    question: ''
  });

  const handleCollapse = (e: React.MouseEvent<HTMLDivElement, MouseEvent>) => {
    const value = e.currentTarget.id === category ? '' : e.currentTarget.id;
    setCategory(value);
  }

  const reset = () => {
    setCategory('');
    setData({
      category: '',
      question: ''
    });
  }

  return (
    <Layout>
      <SEO title='FAQ' />

      <div className={globalClasses.container}>
        <Header title='Preguntas frecuentes' />

        <div className={globalClasses.content}>
          <Grid container spacing={2} alignItems='center'>
            <Grid item xs={12} sm={6} md={4}>
              <TextField
                select
                fullWidth
                size='small'
                color='primary'
                label='Categoria'
                variant='outlined'
                value={data.category || ''}
                onChange={(e) => setData({ ...data, category: e.target.value })}
              >
                <MenuItem value=''>Ver todos</MenuItem>
                {faqs.map((faq, index) => <MenuItem key={index} value={faq.category}>{faq.category}</MenuItem>)}
              </TextField>
            </Grid>
            <Grid item xs={12} sm={6} md={4}>
              <TextField
                fullWidth
                size='small'
                color='primary'
                label='Pregunta'
                variant='outlined'
                value={data.question || ''}
                onChange={(e) => setData({ ...data, question: e.target.value })}
              />
            </Grid>
            <Grid item xs={12} md={4}>
              <Button
                fullWidth
                variant='contained'
                color='primary'
                onClick={reset}
              >
                Borrar filtros
              </Button>
            </Grid>
            <Grid item xs={12}>
              <Paper>
                <List disablePadding className={classes.list}>
                  {faqs
                    .filter(faq => faq.category.includes(data.category))
                    .map((faq, index) => (
                      <Fragment key={index}>
                        <ListItem button divider id={faq.category} onClick={handleCollapse}>
                          <ListItemText primary={faq.category} />
                          {category === faq.category ? <ExpandLess /> : <ExpandMore />}
                        </ListItem>
                        <Collapse in={category === faq.category} timeout='auto'>
                          <List disablePadding>
                            {faq.questions
                              .filter(question =>
                                question.question.trim().toLowerCase().includes(data.question.trim().toLowerCase()))
                              .map((question, index) => (
                                <ListItem key={index} className={classes.nested}>
                                  <ListItemText
                                    primary={question.question}
                                    secondary={
                                      <span dangerouslySetInnerHTML={{ __html: question.answer }}></span>
                                    }
                                  />
                                </ListItem>
                              ))}
                          </List>
                        </Collapse>
                      </Fragment>
                    ))}
                </List>
              </Paper>
            </Grid>
          </Grid>
        </div>
      </div>
    </Layout>
  );
};

export default FAQ;